import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import fontawesome_JnKv9qFGGymEa8jchv4AtGKiPqz5KDyuOewtt9OIRng from "/vercel/path0/plugins/fontawesome.js";
import gtm_client_FmyIzOQfnL7kfwKGOuBFBaPlhm1W5ajGN2WqAk_C3eg from "/vercel/path0/plugins/gtm.client.js";
import phoneRaw_GvQIBNyDul_Zq4YdlIz3Qr___6yofKpZzQLJFGoEQgs from "/vercel/path0/plugins/phoneRaw.js";
import recaptcha_client_0OZxOZPjzyj2El7ahDSQodFW40LmqNIJbmhP5MoeGDA from "/vercel/path0/plugins/recaptcha.client.js";
import sentry_client_MJTLNmRW1o4nGDwwmnW8ZOFOAcwoUzwlFoet6n_LfqQ from "/vercel/path0/plugins/sentry.client.js";
import slideupdown_guwltdhlHUZAPhdywX7VIP8pg6Pdqflj5hPWCXZZ4kE from "/vercel/path0/plugins/slideupdown.js";
import v_click_outside_W4YaySnP_sFI48tnMP6iqePY2TCEhcwKnPuRfzjjrjI from "/vercel/path0/plugins/v-click-outside.js";
import v_parse_links_G_1qJE76uwsqk6KIXZ41KRuM_Ih_BH8ruucRakcNpIs from "/vercel/path0/plugins/v-parse-links.js";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  fontawesome_JnKv9qFGGymEa8jchv4AtGKiPqz5KDyuOewtt9OIRng,
  gtm_client_FmyIzOQfnL7kfwKGOuBFBaPlhm1W5ajGN2WqAk_C3eg,
  phoneRaw_GvQIBNyDul_Zq4YdlIz3Qr___6yofKpZzQLJFGoEQgs,
  recaptcha_client_0OZxOZPjzyj2El7ahDSQodFW40LmqNIJbmhP5MoeGDA,
  sentry_client_MJTLNmRW1o4nGDwwmnW8ZOFOAcwoUzwlFoet6n_LfqQ,
  slideupdown_guwltdhlHUZAPhdywX7VIP8pg6Pdqflj5hPWCXZZ4kE,
  v_click_outside_W4YaySnP_sFI48tnMP6iqePY2TCEhcwKnPuRfzjjrjI,
  v_parse_links_G_1qJE76uwsqk6KIXZ41KRuM_Ih_BH8ruucRakcNpIs,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]